import React from "react";

export interface ContentContainerProps {
  children: any;
  minHeight?: boolean;
  bgColor?: string;
  paddingClass?: string;
}

const ContentContainer = ({
  children,
  minHeight = false,
  bgColor = "",
  paddingClass = "p-4",
}: ContentContainerProps) => {
  return (
    <div
      className={`container lg mx-auto flex md:justify-center ${paddingClass} ${bgColor} min-h-[${
        minHeight && "80vh"
      }]`}
    >
      <div className={"content-container-inner w-[100%] md:w-[66%] md:mx-auto"}>
        {children}
      </div>
    </div>
  );
};

export default ContentContainer;
