import "@/styles/globals.css";
import "@/styles/index.scss";
import type { AppProps } from "next/app";

import { ApolloProvider } from "@apollo/client";
import client from "../src/apollo/client";
import { ThemeProvider } from "@material-tailwind/react";

import { createContext, useState } from "react";
import BaseWrapper from "@/src/components/Layout/BaseWrapper";
import { Roboto } from "next/font/google";
import { GTAG_ID } from "@/src/constants";
import { GoogleAnalytics } from "@next/third-parties/google";

const roboto = Roboto({
  subsets: ["latin"],
  weight: ["400", "700"],
  display: "swap",
});

export interface AuthObj {
  authToken: string;
  paywallLevel: string;
  userData: string;
  isLoggedIn: boolean;
  userId: string;
  userEmail: string;
  isPaywall: boolean;
  leakyPaywall: boolean;
  subs?: string[];
}

const defaultAuthObj: AuthObj = {
  authToken: "",
  paywallLevel: "",
  userData: "",
  isLoggedIn: false,
  userId: "",
  userEmail: "",
  isPaywall: false,
  leakyPaywall: false,
  subs: [],
};

export interface AuthContextType {
  authContext?: AuthObj;
  setAuthContext?: (auth: any) => void;
}

export const AuthContext = createContext<AuthContextType>({});

const AuthContextProvider = ({ children }: any) => {
  const [authContext, setAuthContext] = useState(defaultAuthObj);

  return (
    <AuthContext.Provider value={{ authContext, setAuthContext }}>
      {children}
    </AuthContext.Provider>
  );
};

export default function App({ Component, pageProps, router }: AppProps) {
  return (
    <main className={roboto.className}>
      <ApolloProvider client={client}>
        <ThemeProvider>
          <AuthContextProvider>
            <BaseWrapper Component={Component} router={router} {...pageProps} />
            <GoogleAnalytics gaId={GTAG_ID} />
          </AuthContextProvider>
        </ThemeProvider>
      </ApolloProvider>
    </main>
  );
}
