import React from "react";
import {
  List,
  ListItem,
  Drawer as TailWindDrawer,
  Typography,
} from "@material-tailwind/react";
import { MenuItemType } from "@/src/types";
import { SCPB_LOGO, SOCIAL_LIST, Static_Menu_Items } from "@/src/constants";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

export interface DrawerProps {
  menuItems: { node: MenuItemType }[];
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  loggedIn?: boolean;
}

const Drawer = ({
  menuItems = [],
  open,
  setOpen,
  loggedIn = false,
}: DrawerProps) => {
  const router = useRouter();
  return (
    <TailWindDrawer
      open={open}
      onClose={() => setOpen(false)}
      className="z-[10000]"
      overlay={true}
    >
      <div className="mb-2 flex justify-between p-4 pb-12 flex-col items-start h-[100%] bg-scpb-dark-blue text-white">
        <div className="w-full h-[4rem] relative">
          <Link href="/" className="max-h-12 md:max-h-full">
            <Image
              priority
              src={SCPB_LOGO}
              alt="SCPB Logo"
              fill
              className="nav-logo"
            />
          </Link>
        </div>
        <List className="gap-1">
          <ListItem key={"home"} className="p-0">
            <Typography
              as="a"
              variant="h4"
              color="white"
              className="p-1 font-normal"
              href={"/"}
            >
              Home
            </Typography>
          </ListItem>
          {menuItems.map(function (menuItem) {
            return (
              <ListItem key={menuItem.node.id} className="p-0">
                <Typography
                  as="a"
                  variant="h4"
                  color="white"
                  className="p-1 font-normal"
                  href={menuItem.node.uri}
                >
                  {menuItem.node.label}
                </Typography>
              </ListItem>
            );
          })}
        </List>
        <hr className="my-3 mt-auto" />
        <List className={"gap-1"}>
          {Static_Menu_Items.map(function (menuItem) {
            if (
              (menuItem.isAuth && loggedIn) ||
              (menuItem.hideForAuth && !loggedIn) ||
              menuItem.showForAll
            ) {
              return (
                <ListItem
                  key={menuItem.href}
                  className="p-0"
                  onClick={() => router.push(menuItem.href)}
                >
                  <Typography
                    as="a"
                    variant="h5"
                    color="white"
                    className="p-1 font-normal"
                    href={menuItem.href}
                  >
                    {menuItem.label}
                  </Typography>
                </ListItem>
              );
            } else if (menuItem.hideForAuth && !loggedIn) {
              return <></>;
            }
          })}
        </List>

        <div className="width-[100] flex gap-2 mx-2">
          {SOCIAL_LIST.map(function (item) {
            return (
              <a
                href={item.href}
                target="__blank"
                key={item.label}
                title={`${item.label}`}
              >
                {React.createElement(item.icon, {})}
              </a>
            );
          })}
        </div>
      </div>
    </TailWindDrawer>
  );
};

export default Drawer;
