import { sendGAEvent } from "@next/third-parties/google";

export interface SendAnalyticsEventProps {
  type: string;
  value: string | number;
}

export function sendAnalyticsEvent({ type, value }: SendAnalyticsEventProps) {
  console.log("Sending ga event:", type, value);
  sendGAEvent("event", type, { value });
}

export function updateUserId(user_id: string) {
  window.dataLayer?.push({ user_id });
}

export function registerMetaData(key: string, value: string) {
  window.dataLayer?.push({ key, value });
}
