import { MenuItemType, NavData } from "@/src/types";
import React, { useContext, useEffect, useState } from "react";
import Nav from "../Nav";
import Sidebar from "../Nav/Sidebar";
import Footer from "./Footer";
import axios from "axios";
import { GetUserdataReturn } from "@/pages/api/getUserData";
import { getCookie, getCookies } from "cookies-next";
import { AuthContext } from "@/pages/_app";

export interface LayoutProps {
  menus: NavData;
  children: any;
}

const Layout = ({ menus, children }: LayoutProps) => {
  const { authContext } = useContext(AuthContext);

  return (
    <div>
      <div className="nav h-[3rem]">
        <Nav menuProps={menus} loggedIn={authContext?.isLoggedIn ?? false} />
      </div>
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
