//@ts-nocheck
import React, { useState } from "react";
import Drawer from "./Drawer";
import TopBar from "./TopBar";
import { NavData } from "@/src/types";
import { IconButton } from "@material-tailwind/react";

export interface NavProps {
  menuProps: NavData;
  loggedIn: boolean;
}

const Nav = ({ menuProps, loggedIn }: NavProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theMenuItems = menuProps?.data?.menus?.nodes[0].menuItems?.edges
    ? menuProps?.data?.menus?.nodes[0].menuItems?.edges
    : [];

  const handleClose = (
    isOpen: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.stopPropagation();
    if (!isOpen && !drawerOpen) {
      //do nothing :)
    } else {
      setDrawerOpen(isOpen);
    }
  };

  return (
    <>
      <div className="fixed top-[0.75rem] left-[0.5rem] z-[101]">
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden text-white"
          ripple={false}
          name="menu-button"
          aria-label="menu-button"
          onClick={(e) =>
            drawerOpen ? handleClose(false, e) : handleClose(true, e)
          }
        >
          {drawerOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <Drawer
        open={drawerOpen}
        setOpen={handleClose}
        menuItems={theMenuItems}
        loggedIn={loggedIn}
      />

      <TopBar
        menuItems={theMenuItems}
        setDrawerOpen={handleClose}
        drawerOpen={drawerOpen}
        loggedIn={loggedIn}
      />
    </>
  );
};

export default Nav;
