import React, { useState } from "react";
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  MobileNav,
  Navbar,
  Typography,
} from "@material-tailwind/react";
import { MenuItemType } from "@/src/types";
import Image from "next/image";
import { SCPB_LOGO, Static_Menu_Items } from "@/src/constants";
import Link from "next/link";

export interface TopBarProps {
  menuItems: { node: MenuItemType }[];
  drawerOpen: boolean;

  setDrawerOpen: (open: boolean) => void;
  loggedIn?: boolean;
}

const buildMenuItems = (menuItems: { node: MenuItemType }[]) => {
  return (
    <>
      <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 hidden lg:flex">
        {menuItems.map(function (menuItem) {
          return (
            <Typography
              as="li"
              variant="small"
              color="blue-gray"
              className="p-1 font-normal"
              key={menuItem.node.id}
            >
              <a
                href={menuItem.node.uri}
                className="flex items-center text-white"
              >
                {menuItem.node.label}
              </a>
            </Typography>
          );
        })}
      </ul>
    </>
  );
};

function AccountDropdown({ loggedIn }: { loggedIn: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);
  return (
    <>
      <Menu open={isOpen} handler={setIsOpen} placement="bottom-end">
        <MenuHandler key={"menu-handler-1"}>
          <Button
            variant="text"
            color="white"
            className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
            name="account-menu"
            aria-label="Account menu"
          >
            <i className="fas fa-user-circle p-0.5 text-[1.2rem]" />

            <i
              className={`fas fa-chevron-down h-3 w-3 transition-transform ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </Button>
        </MenuHandler>
        <MenuList className="p-1 z-[100]" key={"menu-list-1"}>
          {Static_Menu_Items.map(function (menuItem, index) {
            if (
              (menuItem.isAuth && loggedIn && !menuItem.hideForAuth) ||
              (!menuItem.isAuth && !loggedIn) ||
              menuItem.showForAll
            ) {
              return (
                <MenuItem
                  key={`${menuItem.href}-${index}`}
                  onClick={closeMenu}
                  className={`flex items-center gap-2 rounded `}
                >
                  <Link href={menuItem.href} className="w-full">
                    {/* {React.createElement(icon, {
                  className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                  strokeWidth: 2,
                })} */}
                    <Typography
                      as="span"
                      variant="small"
                      className="font-normal"
                      color={"inherit"}
                    >
                      {menuItem.label}
                    </Typography>
                  </Link>
                </MenuItem>
              );
            } else {
              return null;
            }
          })}
        </MenuList>
      </Menu>
    </>
  );
}

const TopBar = ({
  menuItems,
  drawerOpen,
  setDrawerOpen,
  loggedIn = false,
}: TopBarProps) => {
  return (
    <Navbar
      className="sticky top z-10 h-max max-w-full bg-scpb-dark-blue text-white p-2 fixed md:relative rounded-none"
      blurred={false}
    >
      <div className="flex items-center justify-between px-4">
        <Link href="/" className="w-[150px] h-[32px] relative ml-4 md:ml-0">
          <Image fill src={SCPB_LOGO} alt="SCPB_LOGO" />
        </Link>
        <div className="flex items-center gap-4">
          <div className="md:mr-4 md:flex gap-4">
            {buildMenuItems(menuItems)}
            <AccountDropdown loggedIn={loggedIn} />
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default TopBar;
